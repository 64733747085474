.bubbles{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:0;
  overflow:hidden;
  top: 0;
  left: 0;
  pointer-events: none;
}
.bubble{
  position: absolute;
  bottom: -100px;
  background: rgba(245, 247, 164, 0.05);
  border: 0.04em solid rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
  border-radius:50%;
  opacity:0.5;
  animation:fly 15s ease-in-out infinite;
}
.bubble:nth-child(1){
  left: 1%;
  height: 40px;
  width: 40px;
  animation-duration:13s;
}
.bubble:nth-child(2){
  left: 10%;
  height: 20px;
  width: 20px;
  animation-duration:15s;
  animation-delay:1s;
}
.bubble:nth-child(3){
  left: 15%;
  height: 30px;
  width: 30px;
  animation-duration:11s;
  animation-delay:2s;
}
.bubble:nth-child(4){
  left: 30%;
  height: 60px;
  width: 60px;
  animation-duration:17s;
  animation-delay:5s;
}
.bubble:nth-child(5){
  left: 40%;
  height: 30px;
  width: 30px;
  animation-duration:14s;
  animation-delay:0;
}
.bubble:nth-child(6){
  left: 50%;
  height: 15px;
  width: 15px;
  animation-duration:18s;
  animation-delay:8s;
}
.bubble:nth-child(7){
  left: 55%;
  height: 60px;
  width: 60px;
  animation-duration:15s;
  animation-delay:0;
}
.bubble:nth-child(8){
  left: 60%;
  height: 10px;
  width: 10px;
  animation-duration:19s;
  animation-delay:5s;
}
.bubble:nth-child(9){
  left: 65%;
  height: 20px;
  width: 20px;
  animation-duration:15s;
  animation-delay:3s;
}
.bubble:nth-child(10){
  left: 70%;
  height: 55px;
  width: 55px;
  animation-duration:12s;
  animation-delay:11s;
}
.bubble:nth-child(11){
  left: 80%;
  height: 33px;
  width: 33px;
  animation-duration:9s;
  animation-delay:15s;
}
.bubble:nth-child(12){
  left: 90%;
  height: 24px;
  width: 24px;
  animation-duration:13s;
  animation-delay:5s;
}
.bubble:nth-child(13){
  left: 95%;
  height: 20px;
  width: 20px;
  animation-duration:14s;
  animation-delay:4s;
}
.bubble:nth-child(14){
  left: 25%;
  height: 28px;
  width: 28px;
  animation-duration:22s;
  animation-delay:0s;
}
.bubble:nth-child(15){
  left: 20%;
  height: 20px;
  width: 20px;
  animation-duration:19s;
  animation-delay:1s;
}

@keyframes fly{
  0%{
    bottom: -200px;
    transform:translateX(0);
    opacity: 1;
  }
  50%{
    transform:translateX(100px);
  }
  75%{
    transform:translateX(25px);
  }
  100%{
    bottom: 100dvh;
    transform:translateX(-5px);
    opacity: 0;
  }
}